import { Link } from "gatsby";
import React, { useEffect } from "react";
import RightArrow from "../../assets/Icons/go.svg";
import whiteAccordian from "../../assets/Images/Homepage/Learn_More_white.svg";
import Button from "../Button";
import "./MiniCardWithBgImg.scss";

const MiniCardWithBgImg = (props) => {
  useEffect(() => {
    document.getElementById(
      props.MiniCardBgImgId
    ).style.backgroundImage = `url(${props.MiniCardBgImg})`;
  }, [props.MiniCardBgImg, props.MiniCardBgImgId]);

  return (
    <div className={props.textColor ? "" : "appsgridshadow"}>
      <div
        className={
          props.service === "clientStory"
            ? props.cardCustomClass &&
              `miniCrdBgImg-main ${props.cardCustomClass}`
            : `miniCrdBgImg-main1 ${props.cardHgt} ${props.cardCustomClass}`
        }
        id={props.MiniCardBgImgId}
      >
        {props.route !== "" ? (
          <>
            <Link
              style={{ textDecoration: "none", color: "black" }}
              className="on-click-card"
              to={props.route}
            >
              <div
                className={
                  props.textColor
                    ? "miniCrdBgImg-overlay-new"
                    : "miniCrdBgImg-overlay"
                }
              >
                <div
                  className={
                    props.textColor
                      ? "miniCrdBgImg-title-white"
                      : "miniCrdBgImg-title"
                  }
                >
                  {props.title}
                </div>
                <div className="miniCrdBgImg-subtitle">{props.subtitle}</div>
                <div className="accordian-condition">
                  {" "}
                  {props.button !== "no" && (
                    <Button
                      icon={
                        !props.variant && (
                          <img
                            className="effect-btn-svgicon"
                            src={RightArrow}
                            alt="click"
                          />
                        )
                      }
                      customClassName={props.customClassName}
                      tip={props.label}
                      color="primary"
                      label={props.label}
                      variant={props.variant}
                      routepath={props.route}
                      tooltip={props.tooltip}
                    />
                  )}{" "}
                </div>
                <div className="mbl-accordian-condition">
                  {" "}
                  {props.button !== "no" && (
                    <Button
                      icon={
                        !props.variant && (
                          <img
                            className="effect-btn-svgicon"
                            src={whiteAccordian}
                            alt="click"
                          />
                        )
                      }
                      customClassName={props.customClassName}
                      tip={props.label}
                      color="primary"
                      label={props.label}
                      variant={props.variant}
                      routepath={props.route}
                      tooltip={props.tooltip}
                    />
                  )}{" "}
                </div>
              </div>
            </Link>
          </>
        ) : (
          <>
            <div className="on-click-card">
              <div
                className={
                  props.textColor
                    ? "miniCrdBgImg-overlay-new"
                    : "miniCrdBgImg-overlay"
                }
              >
                <div
                  className={
                    props.textColor
                      ? "miniCrdBgImg-title-white"
                      : "miniCrdBgImg-title"
                  }
                >
                  {props.title}
                </div>
                <div className="miniCrdBgImg-subtitle">{props.subtitle}</div>
                <div className="accordian-condition">
                  {" "}
                  {props.button !== "no" && (
                    <Button
                      icon={
                        !props.variant && (
                          <img
                            className="effect-btn-svgicon"
                            src={RightArrow}
                            alt="click"
                          />
                        )
                      }
                      customClassName={props.customClassName}
                      tip={props.label}
                      color="primary"
                      label={props.label}
                      variant={props.variant}
                      routepath={props.route}
                      tooltip={props.tooltip}
                    />
                  )}{" "}
                </div>
                <div className="mbl-accordian-condition">
                  {" "}
                  {props.button !== "no" && (
                    <Button
                      icon={
                        !props.variant && (
                          <img
                            className="effect-btn-svgicon"
                            src={whiteAccordian}
                            alt="click"
                          />
                        )
                      }
                      customClassName={props.customClassName}
                      tip={props.label}
                      color="primary"
                      label={props.label}
                      variant={props.variant}
                      routepath={props.route}
                      tooltip={props.tooltip}
                    />
                  )}{" "}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MiniCardWithBgImg;
